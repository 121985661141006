import { getApi, ApiInterface } from '@navvis/ivion';

getApi("https://twin4venuesstationber.iv.navvis.com/")
    .then((iv: ApiInterface) => {
        let ivApi = iv;
    });

const lhs1 = document.getElementById("gohome");
const lhs2 = document.getElementById("gohomestation");

function confirmation(ev) {
    const ask = window.confirm("\r\nAchtung!\r\n\r\nSie verlassen die Websites von Neumann&Müller Veranstaltungstechnik.");
    return ask;
}

lhs1.onclick = confirmation;
lhs2.onclick = confirmation;

const acceptbtns = document.getElementsByClassName("btnAccept");
const disclaimer = document.getElementById("disclaimercontainer");

var existingCookie = document.cookie;
if (existingCookie.indexOf("accepted") >= 0) {
    disclaimer.className = "hide";
}

function accept() {
    disclaimer.classList.add("hide");
    document.cookie = document.cookie + " accepted";
}

for (let btn of acceptbtns) {
    (<HTMLButtonElement>btn).onclick = accept;
}